<template>
    <div>
        <Header/>
        <div class="page-title-area">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <h2>About Us</h2>
                    </div>
                </div>
            </div>
            <div class="shape1"><img src="assets/img/shape1.png" alt="shape"></div>
            <div class="shape2 rotateme"><img src="assets/img/shape2.svg" alt="shape"></div>
            <div class="shape3"><img src="assets/img/shape3.svg" alt="shape"></div>
            <div class="shape4"><img src="assets/img/shape4.svg" alt="shape"></div>
            <div class="shape5"><img src="assets/img/shape5.png" alt="shape"></div>
            <div class="shape6 rotateme"><img src="assets/img/shape4.svg" alt="shape"></div>
            <div class="shape7"><img src="assets/img/shape4.svg" alt="shape"></div>
            <div class="shape8 rotateme"><img src="assets/img/shape2.svg" alt="shape"></div>
        </div>


        <div class="about-area ptb-80">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <div class="about-image">
                            <img src="assets/img/1.png" alt="image">
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="about-content">
                            <div class="section-title">
                                <h2>About Us</h2>
                                <div class="bar"></div>
                                <p>Affordability & Elegance</p>
                            </div>
                            <p>The Story behind 80Leaves is a note book(which was widely used a few decades ago) called 80Leaves</p>
                            <p>This book is ususally the epitome of comprehensive documentation of study as it usually contains all a student needes
                                to excell in his/her studeies.
                            </p>
                            <p>We are a simulation of this as provide services that ensures excellence in your administrative activities</p>
                        </div>
                    </div>
                </div>
                <div class="about-inner-area">
                    <div class="row">
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="about-text">
                                <h3>Our History</h3>
                                <p>We started giving affordable digital services to schools a few years back
                                    and later realized dozens of schools are still running their sctivities manually, 
                                    which is ususally a function of ignorance or inaffordability.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="about-text">
                                <h3>Our Mission</h3>
                                <p>Our mission is to helps your school grow exponentially by providing a comprehensive And
                                    user friendly school management system
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                            <div class="about-text">
                                <h3>Who we are</h3>
                                <p>
                                    We are a team of software engineers, designers and business owners, whose objective is to
                                    make your school's processes smarter and easier. 
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Footer/>
    </div>
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
export default {
    components:{
        Header,
        Footer
    }
}
</script>