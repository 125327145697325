<template>
    <header id="header" class="headroom">
        <div class="startp-responsive-nav">
            <div class="container">
                <div class="startp-responsive-menu">
                    <div class="logo">
                        <router-link :to="{name:'home'}">
                            <img src="assets/img/logo.jpg" height="100" alt="logo">
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
        <div class="startp-nav">
            <div class="container">
                <nav class="navbar navbar-expand-md navbar-light">
                    <a class="navbar-brand" :to="{name:'home'}" ><img src="assets/img/logo.jpg" height="100" alt="logo"></a>
                    <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                        <ul class="navbar-nav nav ml-auto">
                            <li class="nav-item"><router-link :to="{name:'home'}" class="nav-link active">Home <i
                                        data-feather="chevron-down"></i></router-link>   
                            </li>
                            <li class="nav-item"><router-link :to="{name:'about'}" class="nav-link">About <i
                                        data-feather="chevron-down"></i></router-link>
                            </li>
                            
                            <!-- <li class="nav-item"><a href="#talk" target="_self" class="nav-link">Contact</a></li> -->
                        </ul>
                    </div>
                    <!-- <div class="others-option">
                        <a href="cart.html" class="cart-wrapper-btn"><i
                                data-feather="shopping-cart"></i><span>0</span></a>
                        <a href="contact.html" class="btn btn-light">Support</a>
                        <a href="login.html" class="btn btn-primary">Login</a>
                    </div> -->
                </nav>
            </div>
        </div>
        <div class="others-option-for-responsive">
            <div class="container">
                <div class="dot-menu">
                    <div class="inner">
                        <div class="circle circle-one"></div>
                        <div class="circle circle-two"></div>
                        <div class="circle circle-three"></div>
                    </div>
                </div>
                <!-- <div class="container">
                    <div class="option-inner">
                        <div class="others-option">
                            <a href="cart.html" class="cart-wrapper-btn"><i
                                    data-feather="shopping-cart"></i><span>0</span></a>
                            <a href="contact.html" class="btn btn-light">Support</a>
                            <a href="login.html" class="btn btn-primary">Login</a>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </header>
</template>

<script>
export default {
    
}
</script>