<template>
    <footer class="footer-area bg-f7fafd">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="single-footer-widget">
                        <div class="logo">
                            <a href="index.html"><img src="assets/img/logo.jpg" height="100" alt="logo"></a>
                        </div>
                        <p>
                            A comprehensive school management system
                        </p>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="single-footer-widget pl-5">
                        <h3>Company</h3>
                        <ul class="list">
                            <li><router-link :to="{name:'home'}">Home</router-link></li>
                            <li><a :to="{name:'about'}">About</a></li>
                        </ul>
                    </div>
                </div>
                <!-- <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="single-footer-widget">
                        <h3>Support</h3>
                        <ul class="list">
                            <li><a href="faq.html">FAQ's</a></li>
                            <li><a href="privacy-policy.html">Privacy Policy</a></li>
                            <li><a href="terms-conditions.html">Terms & Condition</a></li>
                            <li><a href="contact.html">Support Us</a></li>
                            <li><a href="contact.html">Contact Us</a></li>
                        </ul>
                    </div>
                </div> -->
                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="single-footer-widget">
                        <h3>Contact</h3>
                        <ul class="footer-contact-info">
                            <li><i data-feather="mail"></i> Email: hello@80Leaves.com
                            </li>
                        </ul>
                        <ul class="social-links">
                            <li><a href="#" class="facebook" target="_blank"><i data-feather="facebook"></i></a></li>
                            <li><a href="#" class="twitter" target="_blank"><i data-feather="twitter"></i></a></li>
                            <li><a href="#" class="instagram" target="_blank"><i data-feather="instagram"></i></a></li>
                            <li><a href="#" class="linkedin" target="_blank"><i data-feather="linkedin"></i></a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12">
                    <div class="copyright-area">
                        <p>Copyright @2022 80Leaves. </p>
                    </div>
                </div>
            </div>
        </div>
        <img src="assets/img/map.png" class="map" alt="map">
        <div class="shape1"><img src="assets/img/shape1.png" alt="shape"></div>
        <div class="shape8 rotateme"><img src="assets/img/shape2.svg" alt="shape"></div>
    </footer>
</template>

<script>
export default {
    
}
</script>