import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '@/views/Home'
import About from '@/views/About'
import Error404 from '@/views/Error404'

Vue.use(VueRouter)

const routes = [
    {
        name:'home',
        component:Home,
        path:'/'
    },
    {
        name:'about',
        component:About,
        path:'/about'
    },
    {
        name:'error',
        component:Error404,
        path:'*'
    }
    
    
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router 